import { decodeToken } from 'app/utils/jwtDecode';
import axios from 'axios';

const TIMEOUT = 1 * 60 * 1000;
const REFRESH_URL = `${SERVER_API_URL}customer-consumer/authentication/oauth/refresh/token`;
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const unInterceptedAxiosInstance = axios.create({
  baseURL: SERVER_API_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

const setupAxiosInterceptors = onUnauthenticated => {
  const authTokenSetting = async config => {
    if (!config.baseURL?.startsWith(SERVER_API_URL)) {
      return config;
    }
    config.headers = config.headers || {};
    config.headers.language = 'en_US';
    return config;
  };

  const doRefreshToken = async () => {
    const refreshToken = JSON.parse(sessionStorage.getItem('refresh_token')) || JSON.parse(localStorage.getItem('refresh_token'));
    if (!refreshToken) {
      log_out();
      return null;
    }

    const decoded = decodeToken(refreshToken);
    if (Date.now() >= decoded.exp * 1000) {
      log_out();
      return null;
    } else {
      try {
        const response = await unInterceptedAxiosInstance.post(REFRESH_URL, { refresh_token: refreshToken });
        if (response?.data?.status === true && response?.data?.access_token) {
          const newAccessToken = response.data.access_token;
          const newRefreshToken = response.data.refresh_token;
          localStorage.setItem('access_token', JSON.stringify(newAccessToken));
          localStorage.setItem('refresh_token', JSON.stringify(newRefreshToken));
          sessionStorage.setItem('access_token', JSON.stringify(newAccessToken));
          sessionStorage.setItem('refresh_token', JSON.stringify(newRefreshToken));
          return newAccessToken;
        } else {
          log_out();
          return null;
        }
      } catch (err) {
        console.log('Error refreshing token:', err);
        log_out();
        return null;
      }
    }
  };

  const onRequestSuccess = async config => {
    let accessToken = JSON.parse(sessionStorage.getItem('access_token')) || JSON.parse(localStorage.getItem('access_token'));
    if (accessToken) {
      const decoded = decodeToken(accessToken);
      if (Date.now() >= decoded.exp * 1000) {
        console.log('Token Expired');
        const newAccessToken = await doRefreshToken();
        if (newAccessToken) {
          config.headers.Authorization = `Bearer ${newAccessToken}`;
        }
      } else {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return authTokenSetting(config);
  };

  const onResponseSuccess = response => response;

  const onResponseError = err => {
    const status = err.response ? err.response.status : 0;
    if (status === FORBIDDEN || status === UNAUTHORIZED) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export const log_out = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('user_type');
};

export default setupAxiosInterceptors;
