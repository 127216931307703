import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { IQueryParams, createEntitySlice, EntityState } from 'app/shared/reducers/reducer.utils';
import { ILoanProfile, defaultValue } from 'app/shared/model/loan-profile.model';

const initialState: EntityState<ILoanProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  selectedStatus: '',
}; 

const apiUrl = `${SERVER_API_URL}core-banking/flender/loans/get-loan-details-list`;

export const getEntities = createAsyncThunk('loanStatus/fetch_entity_list', async ({ index, count, searchStatus }: IQueryParams) => {
  const requestUrl = `${apiUrl}?index=${index}&count=${count}&searchStatus=${searchStatus}`;
  return axios.get<ILoanProfile[]>(requestUrl);
});

export const LoanStatusSlice = createEntitySlice({
  name: 'loanStatus',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const links = parseHeaderForLinks(headers.link);

        return {
          ...state,
          loading: false,
          links,
          entities: loadMoreDataWhenScrolled(state.entities, data, links),
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.loading = true;
      });
  },
});

export const { reset } = LoanStatusSlice.actions;

export default LoanStatusSlice.reducer;
