import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import './login.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import { ValidatedForm, ValidatedField } from 'react-jhipster';
import regexPattern from 'app/utils/regexPattern';

export const Login = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const pageLocation = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [totp, setTotp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [user_type, setUser_type] = useState('');

  const handleSubmit = () => {
    dispatch(login(email, password, totp, user_type));

    setEmail('');
    setPassword('');
    setTotp('');
    setUser_type('');
    setShowPassword(false);
  };

  const { from } = pageLocation.state || { from: { pathname: '/', search: pageLocation.search } };
  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }

  function handleSelect(e: any) {
    setUser_type(e.target.value);
  }

  return (
    <div className="custom-body vh-100 overflow-auto">
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card card-height custom-card-background mt-4">
              <div className="card-body text-centre">
                <img src="content/images/logo-jhipster.png" alt="Logo" className="d-block mx-auto mb-4 mt-4 logo-img" />
                <h1 className="text-center mb-3 mt-3 fs-3 heading-color">Login into your account</h1>
                <div>
                  <ValidatedForm onSubmit={handleSubmit}>
                    <ValidatedField
                      type="select"
                      label='Access Type'
                      name="accessType"
                      id="accessType"
                      value={user_type}
                      onChange={handleSelect}
                      validate={{ required: 'Please select an access type' }}
                      className=" custom-placeholder mb-3 mx-5 adjust-width"
                    >
                      <option value="">Select</option>
                      <option value="READ">Read Access</option>
                      <option value="ROLE_READWRITE">Read & Write Access</option>
                    </ValidatedField>


                    <ValidatedField
                      type="text"
                      label='Email'
                      className=" custom-placeholder mb-3 mx-5 adjust-width"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                      validate={{
                        required: 'Email cannot be empty',
                        pattern: regexPattern.email
                      }}

                    />
                    <ValidatedField
                      type={showPassword ? 'text' : 'password'}
                      className="custom-placeholder mb-3 mx-5 adjust-width"
                      label='Password'
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={event => setPassword(event.target.value)}
                      validate={{
                        required: 'Password cannot be empty',
                        // pattern: regexPattern.PASSWORD
                      }}
                    />

                    <ValidatedField
                      type="text"
                      className="custom-placeholder mb-3 mx-5 adjust-width"
                      id="totp"
                      label='TOTP'
                      name="totp"
                      placeholder="Enter your Totp"
                      value={totp}
                      onChange={event => setTotp(event.target.value)}
                      validate={{
                        required: 'TOTP cannot be empty',
                        pattern: regexPattern.Numbers
                      }}
                    />
                    <div className="row justify-content-center adjust-width">
                      <button type="submit" className="btn btn-primary mt-4 mb-3 w-25">
                        Login
                      </button>
                    </div>
                  </ValidatedForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
