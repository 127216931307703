import dayjs from 'dayjs';
import { ILoanFunder } from 'app/shared/model/loan-funder.model';
import { ILoanDisbursement } from 'app/shared/model/loan-disbursement.model';
import { ILoanRepayment } from 'app/shared/model/loan-repayment.model';

export interface ILoan {
  id?: number;
  loanId?: string | null;
  customerId?: string | null;
  loanAmount?: number | null;
  totalLoanAmount?: number | null;
  originationFeePercentage?: number | null;
  monthlyFeePercentage?: number | null;
  tenureInDays?: number | null;
  tenurePeriodFrom?: dayjs.Dayjs | null;
  tenurePeriodTo?: dayjs.Dayjs | null;
  holdBackAmountPercentage?: number | null;
  expectedRepaymentAmount?: number | null;
  preparedBy?: string | null;
  approvedBy?: string | null;
  preparedDate?: dayjs.Dayjs | null;
  approvedDate?: dayjs.Dayjs | null;
  loanFunders?: ILoanFunder[] | null;
  loanDisbursements?: ILoanDisbursement[] | null;
  loanRepayments?: ILoanRepayment[] | null;
  loanProduct?: ILoan | null;
}

export const defaultValue: Readonly<ILoan> = {};
