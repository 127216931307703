import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILoanFunder, defaultValue } from 'app/shared/model/loan-funder.model';

const initialState: EntityState<ILoanFunder> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  allLenderNames: [],
};

// Actions
const apiUrl = `${SERVER_API_URL}core-banking/flender/loans/lender-report`;
const fetchLenderUrl = `${SERVER_API_URL}core-banking/flender/lender/get-list-of-lender-names`;



export const getEntities = createAsyncThunk('loanFunder/fetch_entity_list', async ({ count, index, businessName }: IQueryParams) => {
  const queryParams = new URLSearchParams({
    index: index.toString(),
    count: count.toString(),
    businessName: businessName,
  });

  const requestUrl = `${apiUrl}?${queryParams.toString()}`;
  return axios.get<ILoanFunder[]>(requestUrl);
});


export const fetchLenderNames = createAsyncThunk('loanFunder/fetchLenderNames', async () => {
  const response = await axios.get(fetchLenderUrl);
  return response.data;
});


export const getEntity = createAsyncThunk(
  'loanFunder/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ILoanFunder>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'loanFunder/create_entity',
  async (entity: ILoanFunder, thunkAPI) => {
    return axios.post<ILoanFunder>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'loanFunder/update_entity',
  async (entity: ILoanFunder, thunkAPI) => {
    return axios.put<ILoanFunder>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'loanFunder/partial_update_entity',
  async (entity: ILoanFunder, thunkAPI) => {
    return axios.patch<ILoanFunder>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'loanFunder/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<ILoanFunder>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

// slice

export const LoanFunderSlice = createEntitySlice({
  name: 'loanFunder',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(fetchLenderNames.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(fetchLenderNames.fulfilled, (state, action) => {
        state.loading = false;
        state.allLenderNames = action.payload;
      })
      .addCase(fetchLenderNames.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const links = headers.link ? parseHeaderForLinks(headers.link) : {};

        return {
          ...state,
          loading: false,
          links,
          entities: loadMoreDataWhenScrolled(state.entities, data, links),
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })

      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
    
      
  },
});

export const { reset } = LoanFunderSlice.actions;

// Reducer
export default LoanFunderSlice.reducer;
