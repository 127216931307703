import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue } from 'app/shared/model/loan-repayment.model';
import { ILoanProfile } from 'app/shared/model/loan-profile.model';
import { IBorrower } from 'app/shared/model/borrower.model';

const initialState: EntityState<ILoanProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  loanIdList: [],
  termLoanBorrowers: [],
};
const termLoanBorrowerListApiUrl = `${SERVER_API_URL}core-banking/flender/borrower/all?loanType=TERM_LOAN`;
const termLoanListingApiUrl = `${SERVER_API_URL}core-banking/flender/term-loan/loan-list`;
const termLoanProfileApiUrl = `${SERVER_API_URL}core-banking/flender/term-loan/loanProfile`;
const termLoanIDListApiUrl = `${SERVER_API_URL}core-banking/flender/term-loan/getAllTermLoanIdList`;

export const termLoanBorrowerList = createAsyncThunk('termLoanProfile/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${termLoanBorrowerListApiUrl}`;
  const response = axios.get<IBorrower[]>(requestUrl);
  return response;
});

export const termLoanIDList = createAsyncThunk('termLoanProfile/termloan_id_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${termLoanIDListApiUrl}`;
  const response = axios.get<IBorrower[]>(requestUrl);
  return response;
});
export const getTermloanListEntity = createAsyncThunk(
  'termLoanProfile/fetch_loan_id_list',
  async (borrowerId: string) => {
    const requestUrl = `${termLoanListingApiUrl}?borrowerId=${borrowerId}`;
    const response = await axios.get<ILoanProfile[]>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const getTermloanProfileEntity = createAsyncThunk(
  'termLoanProfile/fetch_term_loan_entity',
  async (loanId: any) => {
    const requestUrl = `${termLoanProfileApiUrl}?loanId=${loanId}`;
    const response = await axios.get<ILoanProfile>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const TermLoanSlice = createEntitySlice({
  name: 'termLoanProfile',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getTermloanListEntity.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getTermloanListEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.loanIdList = action.payload;
        state.errorMessage = null;
      })
      .addCase(getTermloanListEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getTermloanProfileEntity.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getTermloanProfileEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
        state.errorMessage = null;
      })
      .addCase(getTermloanProfileEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(termLoanBorrowerList.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(termLoanBorrowerList.fulfilled, (state, action) => {
        state.loading = false;
        state.termLoanBorrowers = action.payload.data;
        state.errorMessage = null;
      })
      .addCase(termLoanBorrowerList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(termLoanIDList.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(termLoanIDList.fulfilled, (state, action) => {
        state.loading = false;
        state.termLoanIdListEntity = action.payload.data;
        state.errorMessage = null;
      })
      .addCase(termLoanIDList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = TermLoanSlice.actions;

export default TermLoanSlice.reducer;
