import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Loan from './loan';
import LoanFunder from './loan-funder';
import LoanRepayment from './loan-repayment';
import Borrower from './borrower';
import FunderDetails from './funder-details';
import LoanDetails from './loan-details';
import FunderLoanContribution from './funder-loan-contribution';
import AccountCreation from './account-creation/account-creation';
import Journal from './journal/journal';
import Reporting from './transaction-posting/transaction-posting';
import Approval from './transaction-approval';
import GeneralLedgerRoutes from './general-ledger';
import { LoanProfile } from './loan-profile/loan-profile';
import LoanStatus from './loan-status/loan-status';

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        {/* <Route path="account-creation/*" element={<AccountCreation />} />
        <Route path="Journal/*" element={<Journal />} />
        <Route path="borrower/*" element={<Borrower />} />
        <Route path="funder-details/*" element={<FunderDetails />} />
        <Route path="loan-details/*" element={<LoanDetails />} />
        <Route path="loan-status/*" element={<LoanStatus />} />
        <Route path="lender-profile/*" element={<LoanFunder />} />
        <Route path="funder-loan-contribution/*" element={<FunderLoanContribution />} />
        <Route path="generalLedger/*" element={<GeneralLedgerRoutes />} />
        <Route path="loan-reports/*" element={<Loan />} />
        <Route path="reporting/*" element={<Reporting />} />
        <Route path="approval/*" element={<Approval />} />
        <Route path="loan-repayment/*" element={<LoanRepayment />} />
        <Route path="loan-profile/*" element={<LoanProfile />} /> */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
