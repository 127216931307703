import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILoanProfile } from 'app/shared/model/loan-profile.model';

const initialState: EntityState<ILoanProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: [],
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  selectedStatus: '',
};

const updateTotalPaidUrl = `${SERVER_API_URL}core-banking/flender/term-loan/apply/manualRepayment`;

export const editTotalPaid = createAsyncThunk(
  'termloanSchedule/edit_total_paid',
  async ({
    loanId,
    instalmentNumber,
    amount,
    postingDate,
    postingNote,
  }: {
    loanId: string;
    instalmentNumber: number;
    amount: number;
    postingDate: string;
    postingNote: string;
  }) => {
    const requestUrl = `${updateTotalPaidUrl}?loanId=${encodeURIComponent(
      loanId,
    )}&instalmentNumber=${instalmentNumber}&amount=${amount}&postingDate=${postingDate}&postingNote=${postingNote}`;
    try {
      const response = await axios.post<ILoanProfile[]>(requestUrl);
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
  { serializeError: serializeAxiosError },
);

export const TermloanRepaymentSlice = createEntitySlice({
  name: 'termloanRepayment',
  initialState,
  extraReducers(builder) {
    builder

      .addCase(editTotalPaid.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(editTotalPaid.fulfilled, (state, action) => {
        state.loading = false;
        state.editedTotalPaid = action.payload;
      })
      .addCase(editTotalPaid.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = TermloanRepaymentSlice.actions;

export default TermloanRepaymentSlice.reducer;
