import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { EntityState, createEntitySlice, serializeAxiosError, EntityParams } from 'app/shared/reducers/reducer.utils';
import { ITransactionPosting } from 'app/shared/model/transaction-posting.model';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';

const initialState: EntityState<ITransactionPosting> = {
  loading: false,
  errorMessage: null,
  entities: [],
  links: { next: 0 },
  totalItems: 0,
  entity: undefined,
  updating: false,
  updateSuccess: false,
  holdbackRate: null,
  principalAmount: null,
};

const apiUrl = `${SERVER_API_URL}core-banking/flender/`;
const postingUrl = `${apiUrl}transactions/save-common-account-transactions`;

export const getEntities = createAsyncThunk(
  'transactionPosting/fetch_entity_list',
  async ({ page, size, sort, startDate, endDate, index, count }: EntityParams) => {
    const requestUrl = `${apiUrl}transactions/save-common-account-transactions`;
    return axios.get<ITransactionPosting[]>(requestUrl);
  },
);

export const getHoldBackAndOutstandingPrincipalAmount = createAsyncThunk(
  'transactionPosting/getHoldBackAndOutstandingPrincipalAmount',
  async (loanId: string) => {
    const requestUrl = `${apiUrl}loans/getHoldBackAndOutstandingPrincipalAmount?loanId=${encodeURIComponent(loanId)}`;
    return axios.get<ITransactionPosting[]>(requestUrl);
  },
);

export const updateHoldBackPercentage = createAsyncThunk(
  'transactionPosting/update_holdback_percentage',
  async ({ loanId, holdBackPercentage }: { loanId: string; holdBackPercentage: number }) => {
    const requestUrl = `${apiUrl}loans/update-holdBack-rate`;
    const requestData = {
      loan_unique_id: loanId,
      holdback_rate: holdBackPercentage,
    };
    return axios.put(requestUrl, requestData);
  },
  { serializeError: serializeAxiosError },
);

export const updatePrincipalAmount = createAsyncThunk(
  'transactionPosting/update_Principal_Amount',
  async ({ loanId, outstandingAmount }: { loanId: string; outstandingAmount: number }) => {
    const requestUrl = `${apiUrl}loans/manual-repayment`;
    const requestData = {
      loan_unique_id: loanId,
      principal_amount: outstandingAmount,
    };
    return axios.post(requestUrl, requestData);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk('transactionPosting/create_entity', async (entityData: ITransactionPosting) => {
  return axios.post<ITransactionPosting>(postingUrl, entityData);
});

export const TransactionPostingSlice = createEntitySlice({
  name: 'transactionPosting',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { headers }: any = action.payload;
        const { content }: any = action.payload?.data;
        const links = headers && headers.link ? parseHeaderForLinks(headers.link) : '';
        return {
          ...state,
          loading: false,
          links,
          entities: loadMoreDataWhenScrolled(state.entities, content, links),
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          updateSuccess: true,
        };
      })
      .addMatcher(isPending(createEntity), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getHoldBackAndOutstandingPrincipalAmount), (state, action) => {
        state.loading = false;
        state.holdBackAndOutstandingPrincipalAmount = action.payload;
        state.errorMessage = null;
      })
      .addMatcher(isPending(getHoldBackAndOutstandingPrincipalAmount), state => {
        state.errorMessage = null;
        state.loading = true;
      });
  },
});

export const { reset } = TransactionPostingSlice.actions;

export default TransactionPostingSlice.reducer;
