import { ILoan } from 'app/shared/model/loan.model';

export interface ILoanFunder {
  id?: number;
  data?: string | null;
  loanId?: string | null;
  funderId?: string | null;
  fundingPercentage?: number | null;
  fundingAmount?: number | null;
  funderFeePercentage?: number | null;
  loan?: ILoan | null;
  lender_business_name?: string | null;
}

export const defaultValue: Readonly<ILoanFunder> = {};
