import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTermloanListEntity } from '../term-loan-profile/term-loan-profile.reducer';
import { getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { Button } from 'reactstrap';

const ServicingFeeReport = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const borrowerNameList = useAppSelector(state => state.termLoanProfile?.termLoanBorrowers);
  const borrowerIdList = useAppSelector(state => state.loanRepayment?.borrowerIdList);
  const termLoanIdList = useAppSelector(state => state.termLoanProfile?.loanIdList);

  useEffect(() => {
    if (loanId) {
      fetchReport();
    }
  }, [loanId]);

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);
    setBorrowerId('');
    setLoanId('');
    setErrorMessage(null);
    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      setErrorMessage('Error fetching borrower ID list');
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);
    setLoanId('');
    setErrorMessage(null);

    try {
      await dispatch(getTermloanListEntity(selectedBorrowerId));
    } catch (error) {
      setErrorMessage('Error fetching loan ID list');
    }
  };

  const handleLoanIdChange = event => {
    const selectedLoanId = event.target.value;
    setLoanId(selectedLoanId);
    setErrorMessage(null);
  };
  const downloadReportCSV = async () => {
    if (!loanId || !borrowerId) {
      setErrorMessage('Please select all fields before viewing the report.');
      return;
    }

    try {
      const response = await axios.get(`${REPORT_URL}/jasperserver/rest_v2/reports/jasper/Term_Loan_Book.csv`, {
        params: {
          Loan_Id: loanId,
        },
        auth: {
          username: 'jasperadmin',
          password: 'bitnami',
        },
        responseType: 'arraybuffer',
      });
      const file = new Blob([response.data], { type: 'text/csv' });
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `Term_Loan_Book_${loanId}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setErrorMessage(null);
    } catch (err) {
      console.error('Error fetching the report:', err);
      setErrorMessage('Failed to load report');
    }
  };
  const fetchReport = async () => {
    if (!loanId || !borrowerId) {
      setErrorMessage('Please select all fields before viewing the report.');
      return;
    }

    try {
      const response = await axios.get(`${REPORT_URL}/jasperserver/rest_v2/reports/jasper/Servicing_Fee_Report.pdf`, {
        params: {
          Loan_id: loanId,
        },
        auth: {
          username: 'jasperadmin',
          password: 'bitnami',
        },
        responseType: 'arraybuffer',
      });
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setPdfUrl(fileURL);
      setErrorMessage(null);
    } catch (err) {
      console.error('Error fetching the report:', err);
      setErrorMessage('Failed to load report');
    }
  };

  return (
    <div>
      <h2>Servicing Fee Report</h2>
      <div className="col-md-12 mb-3">
        <div className="d-flex mb-5 mt-5 align-items-center dropDownContainer">
          <div className="col-md-2">
            <label>
              <strong>Borrower Name:</strong>
            </label>
            <select value={borrowerName} onChange={handleBorrowerName}>
              <option>Select Borrower</option>
              {borrowerNameList?.map((borrower, i) => (
                <option key={i} value={borrower.borrowerBusinessName}>
                  {borrower.borrowerBusinessName}
                </option>
              ))}
            </select>
          </div>
          <div className="ms-2 col-md-2">
            <label>
              <strong>Borrower ID:</strong>
            </label>
            <select value={borrowerId} onChange={handleBorrowerId} disabled={!borrowerName}>
              <option>Select Borrower ID</option>
              {borrowerIdList?.map((borrower_Id, index) => (
                <option key={index} value={borrower_Id}>
                  {borrower_Id}
                </option>
              ))}
            </select>
          </div>

          <div className="ms-2 col-md-2">
            <label>
              <strong>Loan ID:</strong>
            </label>
            <select value={loanId} onChange={handleLoanIdChange} className="me-3" disabled={!borrowerId}>
              <option value="">Select Loan</option>
              {termLoanIdList?.map((loanId, i) => (
                <option key={i} value={loanId}>
                  {loanId}
                </option>
              ))}
            </select>
          </div>

          <div className="ms-2 mt-4 col-md-2">
            <Button className="customButton" onClick={fetchReport}>
              View
            </Button>
          </div>
        </div>
        {pdfUrl ? (
          <div className="d-flex justify-content-end">
            <Button className="ms-2  customButton" onClick={downloadReportCSV}>
              Download CSV
            </Button>
          </div>
        ) : (
          <> </>
        )}
      </div>

      {errorMessage && <div className="text-danger">{errorMessage}</div>}

      {pdfUrl && <iframe src={pdfUrl} title="Report Viewer" width="100%" height="600px"></iframe>}
    </div>
  );
};

export default ServicingFeeReport;
