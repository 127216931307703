import dayjs from 'dayjs';
import { ILoan } from 'app/shared/model/loan.model';

export interface ILoanRepayment {
  id?: number;
  transactionId?: string | null;
  loanId?: string | null;
  customerId?: string | null;
  loanPaymentAmount?: number | null;
  paymentDate?: dayjs.Dayjs | null;
  paymentMode?: string | null;
  loan?: ILoan | null;
  loanList?: any;
  borrowerIdList?: any;
  totalItems?: any;
  excelGenerateEntities?:any;
}

export const defaultValue: Readonly<ILoanRepayment> = {};
