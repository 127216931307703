export interface IFunderDetails {
  id?: number;
  lenderUniqueId?: string | null;
  lenderBusinessName?: string | null;
  lenderBusinessEmail?: string | null;
  lenderBusinessRepresentPersonName?: string | null;
  lenderFireAccountIcan?: number | null;
}

export const defaultValue: Readonly<IFunderDetails> = {};
