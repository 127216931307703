import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';

import React from 'react';
import { Card } from 'reactstrap';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { useAppSelector } from 'app/config/store';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/AppRoutes';
import Login from './modules/login/login';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isReadWriteUser = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ROLE_READWRITE]));
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const paddingTop = '10px';
  return (
    <BrowserRouter basename={baseHref}>
      <div>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        {!isAuthenticated ? (
          <ErrorBoundary>
            <Login />
          </ErrorBoundary>
        ) : (
          <div className="app-container" style={{ paddingTop }}>
            <ErrorBoundary>
              <Header
                isAuthenticated={isAuthenticated}
                isAdmin={isAdmin}
                isInProduction={isInProduction}
                isOpenAPIEnabled={isOpenAPIEnabled}
                isReadWriteUser={isReadWriteUser}
              />
            </ErrorBoundary>
            <div className="container-fluid view-container" id="app-view-container">
              <section className="home-section">
                <Card className="home-main-card">
                  <ErrorBoundary>
                    <AppRoutes />
                  </ErrorBoundary>
                </Card>
              </section>
              <Footer />
            </div>
          </div>
        )}
      </div>
    </BrowserRouter>
  );
};

export default App;
