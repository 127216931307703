import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTermloanListEntity, getTermloanProfileEntity, termLoanIDList } from '../term-loan-profile/term-loan-profile.reducer';
import { getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { termLoanBorrowerList } from '../term-loan-profile/term-loan-profile.reducer';
import Select from 'react-select';
import { getTermloanScheduleEntity } from '../term-loan-schedule/term-loan-schedule.reducer';
import { editTotalPaid } from './term-loan-repayment.reducer';
import moment from 'moment';

export const TermLoanManualRepayment = () => {
  const dispatch = useAppDispatch();
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [installmentId, setInstallmentId] = useState<number>(null);
  const [loanId, setLoanId] = useState<string>('');
  const [newTotalPaid, setNewTotalPaid] = useState<string>('');
  const [postingDate, setNewPostingDate] = useState<string>('');
  const [postingNote, setNewpostingNote] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchableLoanId, setSearchableLoanId] = useState(null);
  const [selectedInstallment, setSelectedInstallment] = useState(null);

  const borrowerNameList = useAppSelector(state => state?.termLoanProfile?.termLoanBorrowers);
  const borrowerIdList = useAppSelector(state => state?.loanRepayment?.borrowerIdList);
  const termLoanIdList = useAppSelector(state => state?.termLoanProfile?.loanIdList);
  const termLoanFullIdList = useAppSelector(state => state?.termLoanProfile?.termLoanIdListEntity);
  const termLoanSchedule = useAppSelector(state => state?.termLoanSchedule?.entity);

  useEffect(() => {
    dispatch(termLoanBorrowerList({}));
    dispatch(termLoanIDList({}));
  }, []);

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);
    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);
    try {
      await dispatch(getTermloanListEntity(selectedBorrowerId));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleLoanIdChange = event => {
    setLoanId(event.target.value);
  };

  const handleInstallmentChange = event => {
    const selectedId = Number(event.target.value);
    setInstallmentId(selectedId);
    const installmentDetails = termLoanSchedule.find(inst => inst.instalment === selectedId);
    setSelectedInstallment(installmentDetails || null);
  };

  const viewLoanSchedule = async () => {
    if (loanId) {
      setLoading(true);
      try {
        await dispatch(getTermloanScheduleEntity(loanId));
      } catch (error) {
        setErrorMessage('Error fetching loan schedule');
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage('No Loan ID selected');
    }
  };

  const handleLoanIdSearch = selectedOption => {
    setLoanId(selectedOption?.value || '');
    setSearchableLoanId(selectedOption);
  };

  const loanIdOptions = termLoanFullIdList?.map(loanId => ({
    value: loanId,
    label: loanId,
  }));

  const customComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  const customFilterOption = (option, inputValue) => {
    if (!inputValue) return false;
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  const handleSaveTotalPaid = (loanId: string, instalment: number, totalPaid: string | null, postingDate: string, postingNote: string) => {
    if (!totalPaid) {
      alert('Total Paid amount is required!');
      return;
    }
    console.log('Dispatching editTotalPaid:', {
      loanId,
      instalmentNumber: instalment,
      amount: parseFloat(totalPaid),
      postingDate,
      postingNote,
    });

    dispatch(editTotalPaid({ loanId, instalmentNumber: instalment, amount: parseFloat(totalPaid), postingDate, postingNote }))
      .then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          alert('Total Paid updated successfully!');
          setNewTotalPaid(null);
          dispatch(getTermloanScheduleEntity(loanId));
        } else {
          alert('Failed to update Total Paid');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('An error occurred while updating Total Paid.');
      });
  };

  return (
    <div className="container-fluid">
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Manual Repayment
      </h2>
      <div className="mb-3 d-flex dropDownContainer">
        <div className="d-flex mb-3 mt-3 align-items-center ">
          <div className="col-md-3">
            <label>
              <strong>Borrower Name:</strong>
            </label>
            <select value={borrowerName} onChange={handleBorrowerName}>
              <option>Select Borrower</option>
              {borrowerNameList?.map((borrower, i) => (
                <option key={i} value={borrower.borrowerBusinessName}>
                  {borrower.borrowerBusinessName}
                </option>
              ))}
            </select>
          </div>
          <div className="ms-2 col-md-3">
            <label>
              <strong>Borrower ID:</strong>
            </label>
            <select value={borrowerId} onChange={handleBorrowerId} disabled={!borrowerName}>
              <option>Select Borrower ID</option>
              {borrowerIdList?.map((borrower_Id, index) => (
                <option key={index} value={borrower_Id}>
                  {borrower_Id}
                </option>
              ))}
            </select>
          </div>

          <div className="ms-2 col-md-3">
            <label>
              <strong>Loan ID:</strong>
            </label>
            <select value={loanId} onChange={handleLoanIdChange} className="me-3" disabled={!borrowerId}>
              <option value="">Select Loan</option>
              {termLoanIdList?.map((loanId, i) => (
                <option key={i} value={loanId}>
                  {loanId}
                </option>
              ))}
            </select>
          </div>
          <Button className="customButton ms-2 mt-4" onClick={viewLoanSchedule}>
            Edit
          </Button>
        </div>
        <div className="d-flex  col-md-4 ms-5 mb-3 mt-4 align-items-center ">
          <div className="mt-3">
            <Select
              options={loanIdOptions}
              value={searchableLoanId}
              onChange={handleLoanIdSearch}
              components={customComponents}
              placeholder="Select or search Loan ID..."
              className="react-select-container"
              classNamePrefix="react-select"
              filterOption={customFilterOption}
            />
          </div>
          <div>
            <Button className="customButton ms-1 mt-3" onClick={() => viewLoanSchedule()}>
              Search
            </Button>
          </div>
        </div>
      </div>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {loanId && (
        <div className="mb-3 mt-3 border border-2 p-3 align-items-center ">
          <div>
            <h5>Apply Repayment</h5>
          </div>
          <div>
            <div>
              <div className="d-flex mb-3 mt-3align-items-center ">
                <div className="col-md-2">
                  <label>
                    <strong>Installment</strong>
                  </label>
                  <select value={installmentId || ''} onChange={handleInstallmentChange}>
                    <option value="">Select Installment</option>
                    {termLoanSchedule?.map((installment, i) => (
                      <option key={i} value={installment.instalment}>
                        {installment.instalment}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="ms-2 col-md-2">
                  <label>
                    <strong>Amount</strong>
                  </label>
                  <input
                    type="number"
                    value={newTotalPaid || ''}
                    onChange={e => setNewTotalPaid(e.target.value)}
                    className="posting-form-input"
                  />
                </div>
                <div className="ms-2 col-md-2">
                  <label>
                    <strong>Posting Date</strong>
                  </label>
                  <input
                    type="date"
                    value={moment(postingDate).format('YYYY-MM-DD')}
                    onChange={e => setNewPostingDate(new Date(e.target.value).toISOString())}
                    className="posting-form-input"
                  />
                </div>
                <div className="ms-2 col-md-2">
                  <label>
                    <strong>Posting Note</strong>
                  </label>
                  <input
                    type="textarea"
                    value={postingNote || ''}
                    onChange={e => setNewpostingNote(e.target.value)}
                    className="posting-form-input"
                  />
                </div>
                <Button
                  className="customButton ms-2 mt-3"
                  onClick={() => handleSaveTotalPaid(loanId, installmentId, newTotalPaid, postingDate, postingNote)}
                >
                  Apply Repayment
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedInstallment && (
        <div className="mt-4">
          <h4>Installment Details</h4>
          <Table responsive className="table-striped table-bordered tableDesign">
            <tbody>
              <tr>
                <th>Id</th>
                <td>{selectedInstallment.id || 0}</td>
              </tr>
              <tr>
                <th>Loan Id</th>
                <td>{selectedInstallment.loanId || 'N/A'}</td>
              </tr>
              <tr>
                <th>Installment</th>
                <td>{selectedInstallment.instalment || 0}</td>
              </tr>
              <tr>
                <th>Due Date</th>
                <td>{selectedInstallment.dueDate || 'N/A'}</td>
              </tr>
              <tr>
                <th>Principal Balance Opening</th>
                <td>{selectedInstallment.principalBalanceOpening || 0}</td>
              </tr>
              <tr>
                <th>Principal Expected</th>
                <td>{selectedInstallment.principalExpected || 0}</td>
              </tr>
              <tr>
                <th>Principal Balance Closing</th>
                <td>{selectedInstallment.principalBalanceClosing || 0}</td>
              </tr>
              <tr>
                <th>Interest Expected</th>
                <td>{selectedInstallment.interestExpected || 0}</td>
              </tr>
              <tr>
                <th>Interest Days</th>
                <td>{selectedInstallment.interestDays || 0}</td>
              </tr>
              <tr>
                <th>Billing Days Adjustment</th>
                <td>{selectedInstallment.billingDaysAdjustment || 0}</td>
              </tr>
              <tr>
                <th>Fees Expected</th>
                <td>{selectedInstallment.feesExpected || 0}</td>
              </tr>
              <tr>
                <th>Penalty Expected</th>
                <td>{selectedInstallment.penaltyExpected || 0}</td>
              </tr>
              <tr>
                <th>Total Expected</th>
                <td>{selectedInstallment.totalExpected || 0}</td>
              </tr>
              <tr>
                <th>Principal Paid</th>
                <td>{selectedInstallment.principalPaid || 0}</td>
              </tr>
              <tr>
                <th>Interest Paid</th>
                <td>{selectedInstallment.interestPaid || 0}</td>
              </tr>
              <tr>
                <th>Fee Paid</th>
                <td>{selectedInstallment.feePaid || 0}</td>
              </tr>
              <tr>
                <th>Penalty Paid</th>
                <td>{selectedInstallment.penaltyPaid || 0}</td>
              </tr>
              <tr>
                <th>Total Paid</th>
                <td>{selectedInstallment.totalPaid || 0}</td>
              </tr>
              <tr>
                <th>Paid Date</th>
                <td>{selectedInstallment.paidDateTime || 'N/A'}</td>
              </tr>
              <tr>
                <th>Principal Due</th>
                <td>{selectedInstallment.principalDue || 0}</td>
              </tr>
              <tr>
                <th>Interest Due</th>
                <td>{selectedInstallment.interestDue || 0}</td>
              </tr>
              <tr>
                <th>Fee Due</th>
                <td>{selectedInstallment.feeDue || 0}</td>
              </tr>
              <tr>
                <th>Penalty Due</th>
                <td>{selectedInstallment.penaltyDue || 0}</td>
              </tr>
              <tr>
                <th>Total Due</th>
                <td>{selectedInstallment.totalDue || 0}</td>
              </tr>
              <tr>
                <th>State</th>
                <td>{selectedInstallment.state || 'N/A'}</td>
              </tr>
              <tr>
                <th>Type of Payment</th>
                <td>{selectedInstallment.typeOfPayment || 'N/A'}</td>
              </tr>
              <tr>
                <th>Ad Hoc Fee</th>
                <td>{selectedInstallment.adHocFee || 'N/A'}</td>
              </tr>
              <tr>
                <th>Legal Collection Fee</th>
                <td>{selectedInstallment.legalCollectionFee || 'N/A'}</td>
              </tr>
              <tr>
                <th>Exit Fee</th>
                <td>{selectedInstallment.exitFee || 'N/A'}</td>
              </tr>
              <tr>
                <th>Expected Instalment Date</th>
                <td>{selectedInstallment.expectedInstalmentDate || 'N/A'}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default TermLoanManualRepayment;
