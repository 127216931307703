import './footer.scss';

import React from 'react';


const Footer = () => (

  <></>
);

export default Footer;
