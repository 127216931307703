import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  errorMessage: null,
  accessTokenUrl: null,
};

const apiUrl = `${SERVER_API_URL}banking-integration/flender/security/login-redirect-authorize`;

export const fetchUrl = createAsyncThunk<any, void, {}>(
  'getAccessToken/fetch_url',
  async () => {
    try {
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  { serializeError: serializeAxiosError },
);

const getAccessToken = createSlice({
  name: 'getAccessToken',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUrl.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(fetchUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.accessTokenUrl = action.payload;
      })
      .addCase(fetchUrl.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export default getAccessToken.reducer;
