import dayjs from 'dayjs';

export interface ILoanDetails {
  id?: number;
  loanApplicationUniqueId?: string | null;
  netLoanAmount?: number | null;
  totalGrossLoanAmount?: number | null;
  originationFeeAmount?: number | null;
  totalDailyFeeAmount?: number | null;
  dailyHoldbackPercent?: number | null;
  loanCreationDate?: dayjs.Dayjs | null;
  expectedTerm?: number | null;
  expectedDailyPosReceipts?: number | null;
  expectedDailyHoldback?: number | null;
  flenderId?: string | null;
  funderId?: string | null;
  borrowerId?: string | null;
}

export const defaultValue: Readonly<ILoanDetails> = {};
