import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTermloanListEntity, getTermloanProfileEntity, termLoanIDList } from './term-loan-profile.reducer';
import { getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { termLoanBorrowerList } from './term-loan-profile.reducer';
import NumberFormat from 'react-number-format';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { TextFormat } from 'react-jhipster';
import Select from 'react-select';

export const TermLoanProfile = () => {
  const dispatch = useAppDispatch();
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');
  const [searchableLoanId, setSearchableLoanId] = useState(null);

  const borrowerNameList = useAppSelector(state => state.termLoanProfile?.termLoanBorrowers);
  const borrowerIdList = useAppSelector(state => state.loanRepayment?.borrowerIdList);
  const termLoanIdList = useAppSelector(state => state.termLoanProfile?.loanIdList);
  const termLoanProfile = useAppSelector(state => state.termLoanProfile?.entity);
  const termLoanFullIdList = useAppSelector(state => state.termLoanProfile?.termLoanIdListEntity);

  useEffect(() => {
    dispatch(termLoanBorrowerList({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(termLoanIDList({}));
  }, [dispatch]);

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);

    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);
    try {
      await dispatch(getTermloanListEntity(selectedBorrowerId));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleLoanIdChange = event => {
    setLoanId(event.target.value);
  };

  const handleViewProfile = async () => {
    if (loanId) {
      try {
        await dispatch(getTermloanProfileEntity(loanId));
      } catch (error) {
        console.error('Error fetching loan profile:', error);
      }
    }
  };

  const handleLoanIdSearch = selectedOption => {
    setLoanId(selectedOption?.value || '');
    setSearchableLoanId(selectedOption);
  };

  const loanIdOptions = termLoanFullIdList?.map(loanId => ({
    value: loanId,
    label: loanId,
  }));

  const customComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };
  const customFilterOption = (option, inputValue) => {
    if (!inputValue) return false;
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  return (
    <div className="container-fluid">
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Term Loan Profile
      </h2>
      <div className="mb-3 d-flex dropDownContainer">
        <div className="d-flex mb-3 mt-3 align-items-center ">
          <div className="col-md-3">
            <label>
              <strong>Borrower Name:</strong>
            </label>
            <select value={borrowerName} onChange={handleBorrowerName}>
              <option>Select Borrower</option>
              {borrowerNameList?.map((borrower, i) => (
                <option key={i} value={borrower.borrowerBusinessName}>
                  {borrower.borrowerBusinessName}
                </option>
              ))}
            </select>
          </div>
          <div className="ms-2 col-md-3">
            <label>
              <strong>Borrower ID:</strong>
            </label>
            <select value={borrowerId} onChange={handleBorrowerId} disabled={!borrowerName}>
              <option>Select Borrower ID</option>
              {borrowerIdList?.map((borrower_Id, index) => (
                <option key={index} value={borrower_Id}>
                  {borrower_Id}
                </option>
              ))}
            </select>
          </div>

          <div className="ms-2 col-md-3">
            <label>
              <strong>Loan ID:</strong>
            </label>
            <select value={loanId} onChange={handleLoanIdChange} className="me-3" disabled={!borrowerId}>
              <option value="">Select Loan</option>
              {termLoanIdList?.map((loanId, i) => (
                <option key={i} value={loanId}>
                  {loanId}
                </option>
              ))}
            </select>
          </div>
          <Button className="customButton ms-2 mt-4" onClick={handleViewProfile}>
            View
          </Button>
        </div>
        <div className="d-flex  col-md-4 ms-5 mb-3 mt-4 align-items-center ">
          <div className="mt-3">
            <Select
              options={loanIdOptions}
              value={searchableLoanId}
              onChange={handleLoanIdSearch}
              components={customComponents}
              placeholder="Select or search Loan ID..."
              className="react-select-container"
              classNamePrefix="react-select"
              filterOption={customFilterOption}
            />
          </div>
          <div>
            <Button className="customButton ms-1 mt-3" onClick={() => handleViewProfile()}>
              Search
            </Button>
          </div>
        </div>
      </div>
      <div>
        {termLoanProfile && Object.keys(termLoanProfile).length > 0 ? (
          <div className="table-responsive mainContainer mt-5">
            <Row>
              <Col>
                <div className="mb-3 mt-5 border border-2 p-4">
                  <span className="mb-3">
                    <h4>Profile statement</h4>
                  </span>
                  <div>
                    <div className="mt-3">
                      <span className="mb-3">
                        {' '}
                        <strong>Loan Application Unique ID : </strong>
                        {termLoanProfile.loan_application_unique_id ? termLoanProfile.loan_application_unique_id : 0}
                      </span>
                      <div className="mt-3">
                        <span>
                          {' '}
                          <strong>Borrower ID : </strong>
                          {termLoanProfile?.borrower_id}
                        </span>
                      </div>

                      <div className="mt-3">
                        <span className="mb-3">
                          <strong> Borrower Name : </strong>
                          {termLoanProfile?.borrower_name}
                        </span>
                      </div>
                    </div>
                    <div className="mt-3">
                      <span className="mb-3">
                        <strong>Loan Activation Date : </strong>
                        {termLoanProfile?.loan_activation_date ? (
                          <TextFormat type="date" value={termLoanProfile?.loan_activation_date} format={APP_DATE_FORMAT} />
                        ) : null}{' '}
                      </span>
                    </div>
                    <div className="mt-3">
                      <span className="mt-5">
                        <strong> Report Generation Date: </strong>{' '}
                        {termLoanProfile?.report_generated_date ? (
                          <TextFormat type="date" value={termLoanProfile?.report_generated_date} format={APP_DATE_FORMAT} />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Table responsive className="table-striped table-bordered tableDesign">
              <tbody>
                <tr>
                  <th scope="row">Old Mambu Loan Id</th>
                  <td>{termLoanProfile.old_mambu_loan_id ? termLoanProfile.old_mambu_loan_id : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Net Loan Amount</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.net_loan_amount !== null ? termLoanProfile.net_loan_amount : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Origination Fee</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.origination_fee_amount !== null ? termLoanProfile.origination_fee_amount : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Total Gross Loan Amount</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.total_gross_loan_amount !== null ? termLoanProfile.total_gross_loan_amount : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Interest Rate</th>
                  <td>{termLoanProfile.interest_rate ? termLoanProfile.interest_rate : '0'}%</td>
                </tr>
                <tr>
                  <th scope="row">Installments</th>
                  <td>{termLoanProfile.instalments ? termLoanProfile.instalments : 0}</td>
                </tr>
                <tr>
                  <th scope="row">First Installment Date</th>
                  <td>{termLoanProfile.first_instalment_date ? termLoanProfile.first_instalment_date : '00/00/0000'}</td>
                </tr>
                <tr>
                  <th scope="row">Servicing Fee Rate</th>
                  <td>{termLoanProfile.servicing_fee_rate ? termLoanProfile.servicing_fee_rate : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Servicing Fee Amount</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.servicing_fee_amount !== null ? termLoanProfile.servicing_fee_amount : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Loan Creation Date</th>
                  <td>{termLoanProfile.loan_creation_date ? termLoanProfile.loan_creation_date : '00/00/0000'}</td>
                </tr>

                <tr>
                  <th scope="row">Loan Status</th>
                  <td>{termLoanProfile.loan_status ? termLoanProfile.loan_status : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Repayment Status</th>
                  <td>{termLoanProfile.repayment_status ? termLoanProfile.repayment_status : 0}</td>
                </tr>
                <tr>
                  <th scope="row">Funder ID</th>
                  <td>{termLoanProfile.funder_id ? termLoanProfile.funder_id : 0}</td>
                </tr>
                {/* <tr>
                  <th scope="row">Fixed Monthly Repayment</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.fixed_monthly_repayment !== null ? termLoanProfile.fixed_monthly_repayment : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr> */}
                <tr>
                  <th scope="row">Fixed Monthly Direct Debit</th>
                  <td>
                    <NumberFormat
                      value={termLoanProfile.fixed_monthly_direct_debit !== null ? termLoanProfile.fixed_monthly_direct_debit : 0}
                      thousandSeparator={true}
                      prefix={'€ '}
                      displayType={'text'}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Loan Completion Date</th>
                  <td>{termLoanProfile.loan_completion_date ? termLoanProfile.loan_completion_date : '00/00/0000'}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TermLoanProfile;
