import dayjs from 'dayjs';
import { ILoan } from 'app/shared/model/loan.model';

export interface ILoanDisbursement {
  id?: number;
  transactionId?: string | null;
  loanId?: string | null;
  customerId?: string | null;
  disbursementAmount?: number | null;
  disbursementDate?: dayjs.Dayjs | null;
  repaymentStartDate?: dayjs.Dayjs | null;
  repaymentEndDate?: dayjs.Dayjs | null;
  loan?: ILoan | null;
}

export const defaultValue: Readonly<ILoanDisbursement> = {};
