import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILoanRepayment, defaultValue } from 'app/shared/model/loan-repayment.model';
import { IBorrower } from 'app/shared/model/borrower.model';
import { saveAs } from 'file-saver';

const initialState: EntityState<ILoanRepayment> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  loanList: [],
  borrowerIdList: [],
};

const apiUrl = `${SERVER_API_URL}core-banking/flender`;
const excelUrl = `${SERVER_API_URL}core-banking/flender/loans/generate/repayment-report-excel`;
const pdfUrl = `${SERVER_API_URL}core-banking/flender/loans/generate/customer-repayment-pdf`;

export const getEntities = createAsyncThunk(
  'loanRepayment/fetch_entity_list',
  async ({ borrowerId, loanId, index, count, startDate, endDate, zeroFilter }: IQueryParams) => {
    try {
      const requestUrl = `${apiUrl}/loans/repaymentReport?borrowerId=${borrowerId}&loanId=${loanId}&index=${index}&count=${count}&startDate=${startDate}&endDate=${endDate}&zeroFilter=${zeroFilter}`;
      const response = await axios.get<ILoanRepayment[]>(requestUrl, {
        headers: {
          version: 'v1',
        },
      });
      return { data: response.data, headers: response.headers };
    } catch (error) {
      throw Error(error.message);
    }
  },
);

export const getLoanIdList = createAsyncThunk(
  'loanRepayment/fetch_loan_id',
  async (queryParams: IQueryParams) => {
    const { borrowerId } = queryParams;
    const requestUrl = `${apiUrl}/loans/loanList?borrowerId=${encodeURIComponent(String(borrowerId))}`;
    const response = await axios.get<ILoanRepayment[]>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const getBorrowerNameList = createAsyncThunk(
  'borrower/fetch_borrower_list',
  async (queryParams: IQueryParams) => {
    const requestUrl = `${apiUrl}/borrower/all`;
    const response = await axios.get<IBorrower[]>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);
export const getBorrowerIdList = createAsyncThunk(
  'borrower/fetch_borrower_id_list',
  async (borrowerName: string) => {
    const requestUrl = `${apiUrl}/borrower/get-borrower-id/${encodeURIComponent(borrowerName)}`;
    const response = await axios.get<string[]>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const getExcelGenerateEntities = createAsyncThunk(
  'loanRepayment/fetch_excel_report',
  async ({ loanId }: IQueryParams, { rejectWithValue }) => {
    try {
      const requestUrl = `${excelUrl}?loanId=${encodeURIComponent(loanId)}`;
      const response = await axios.get(requestUrl, {
        responseType: 'blob',
      });
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0].trim() : 'Repayment.xlsx';
      saveAs(response.data, filename);
      return { data: response.data, headers: response.headers };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
export const getPDFGenerateEntities = createAsyncThunk(
  'generalLedger/fetch_pdf_report',
  async ({ loanId }: IQueryParams, { rejectWithValue }) => {
    try {
      const requestUrl = `${pdfUrl}?loanId=${encodeURIComponent(loanId)}`;
      const response = await axios.get(requestUrl, {
        responseType: 'blob',
      });
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0].trim() : 'Repayment.pdf';
      saveAs(response.data, filename);
      return { data: response.data, headers: response.headers };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getEntity = createAsyncThunk(
  'loanRepayment/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ILoanRepayment>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'loanRepayment/create_entity',
  async (entity: ILoanRepayment, thunkAPI) => {
    return axios.post<ILoanRepayment>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'loanRepayment/update_entity',
  async (entity: ILoanRepayment, thunkAPI) => {
    return axios.put<ILoanRepayment>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'loanRepayment/partial_update_entity',
  async (entity: ILoanRepayment, thunkAPI) => {
    return axios.patch<ILoanRepayment>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'loanRepayment/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<ILoanRepayment>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const BorrowerSlice = createEntitySlice({
  name: 'loanRepayment',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getExcelGenerateEntities.pending, state => {
        state.errorMessage = null;
      })
      .addCase(getExcelGenerateEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.excelReport = action.payload;
      })
      .addCase(getExcelGenerateEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getPDFGenerateEntities.pending, state => {
        state.errorMessage = null;
      })
      .addCase(getPDFGenerateEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.excelReport = action.payload;
      })
      .addCase(getPDFGenerateEntities.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const links = headers && headers.link ? parseHeaderForLinks(headers.link) : '';

        return {
          ...state,
          loading: false,
          links,
          entities: loadMoreDataWhenScrolled(state.entities, data, links),
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })

      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
    builder.addMatcher(isFulfilled(getLoanIdList), (state, action) => {
      state.loading = false;
      state.loanList = action.payload;
      state.errorMessage = null;
    });
    builder.addMatcher(isFulfilled(getBorrowerIdList), (state, action) => {
      state.loading = false;
      state.borrowerIdList = action.payload;
      state.errorMessage = null;
    });
    builder.addMatcher(isFulfilled(getExcelGenerateEntities), (state, action) => {
      state.loading = false;
      state.excelGenerateEntities = action.payload;
      state.errorMessage = null;
    });
    builder.addMatcher(isRejected(getExcelGenerateEntities), (state, action) => {
      state.loading = true;
      state.errorMessage = action.error.message;
    });
    builder.addMatcher(isRejected(getLoanIdList), (state, action) => {
      state.loading = false;
      state.errorMessage = action.error.message;
    });
  },
});

export const { reset } = BorrowerSlice.actions;

export default BorrowerSlice.reducer;
