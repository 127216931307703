import dayjs from 'dayjs';

export interface IGeneralLedger {
  id?: string;
  credit?: number | null;
  debit?: number | null;
  currency?: string | null;
  transactionReferenceNumber?: string | null;
  transactionDate?: dayjs.Dayjs | null;
  narration?: string | null;
  loanId?: string | null;
  accountNumber?: string | null;
  debitCreditFlag?: string | null;
  postingDate?: dayjs.Dayjs | null;
  entityId?: string | null;
  entityType?: string | null;
  content?: any;
  loanActivationDate?: any;
}

export const defaultValue: Readonly<IGeneralLedger> = {};
