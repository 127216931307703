import { ILoanProduct } from 'app/shared/model/loan-product.model';

export interface ILoanType {
  id?: number;
  loanTypeId?: string | null;
  loanTypeName?: string | null;
  originationFeePercentage?: number | null;
  monthlyFeePercentage?: number | null;
  loanProducts?: ILoanProduct[] | null;
}

export const defaultValue: Readonly<ILoanType> = {};
