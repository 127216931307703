import { ILoanType } from 'app/shared/model/loan-type.model';

export interface ILoanProduct {
  id?: number;
  loanProductId?: string | null;
  loanProductName?: string | null;
  amountRangeFrom?: number | null;
  amountRangeTo?: number | null;
  riskRating?: string | null;
  customerSegment?: string | null;
  maxTenureInDays?: number | null;
  roiType?: string | null;
  loanType?: ILoanType | null;
  loan?: ILoanType | null;
}

export const defaultValue: Readonly<ILoanProduct> = {};
