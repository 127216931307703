import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue } from 'app/shared/model/loan-repayment.model';
import { ILenderProfile } from 'app/shared/model/lender-investment.model';

const initialState: EntityState<ILenderProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  lenderIdList: [],
  transactionTableData: [],
  lenderBalance: '',
};

const lenderIdListingApiUrl = `${SERVER_API_URL}core-banking/flender/lender/get-lender-id`;
const checkBalanceApiUrl = `${SERVER_API_URL}core-banking/flender/gl-accounts/check-balance`;
const addTransactionApiUrl = `${SERVER_API_URL}core-banking/flender/gl-accounts/credit-debit`;
const viewTransactionApiUrl = `${SERVER_API_URL}core-banking/flender/lender/lender-account-transaction-log`;

export const getLenderId = createAsyncThunk(
  'lenderInvestment/fetch_loan_id_list',
  async (lenderName: any) => {
    const requestUrl = `${lenderIdListingApiUrl}/${encodeURIComponent(lenderName)}`;
    const response = await axios.get<ILenderProfile[]>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const fetchBalance = createAsyncThunk(
  'lenderInvestment/fetch_lender_balance',
  async (lenderId: any) => {
    const requestUrl = `${checkBalanceApiUrl}/${lenderId}`;
    const response = await axios.get<ILenderProfile>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const addTransaction = createAsyncThunk(
  'lenderInvestment/add_transaction',
  async ({ lenderId, amount, transactionType, transactionNote}: { lenderId: string; amount: number; transactionType: string; transactionNote: string }) => {
    const requestUrl = `${addTransactionApiUrl}`;
    const requestData = {
      lenderId: lenderId,
      amount: amount,
      transactionType: transactionType,
      note:transactionNote,
    };
    const response = await axios.post(requestUrl, requestData);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);
export const viewTransactionHistory = createAsyncThunk(
  'lenderInvestment/view_transaction_history',
  async (lenderId: any) => {
    const requestUrl = `${viewTransactionApiUrl}/${lenderId}`;
    const response = await axios.get<ILenderProfile>(requestUrl);
    return response?.data;
  },
  { serializeError: serializeAxiosError },
);

export const LenderInvestmentSlice = createEntitySlice({
  name: 'lenderInvestment',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getLenderId.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getLenderId.fulfilled, (state, action) => {
        state.loading = false;
        state.lenderIdList = action.payload;
        state.errorMessage = null;
      })
      .addCase(getLenderId.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(fetchBalance.pending, state => {
        state.loading = true;
        state.lenderBalance = '';
        state.errorMessage = null;
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.lenderBalance = action.payload;
        state.errorMessage = null;
      })
      .addCase(fetchBalance.rejected, (state, action) => {
        state.loading = false;
        state.lenderBalance = '';
        state.errorMessage = action.error.message;
      })
      .addCase(addTransaction.pending, state => {
        state.updating = true;
        state.updateSuccess = false;
        state.errorMessage = null;
      })
      .addCase(addTransaction.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.errorMessage = null;
      })
      .addCase(addTransaction.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(viewTransactionHistory.pending, state => {
        state.updating = true;
        state.transactionTableData = false;
        state.errorMessage = null;
      })
      .addCase(viewTransactionHistory.fulfilled, (state, action) => {
        state.updating = false;
        state.transactionTableData = action.payload;
        state.errorMessage = null;
      })
      .addCase(viewTransactionHistory.rejected, (state, action) => {
        state.updating = false;
        state.transactionTableData = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = LenderInvestmentSlice.actions;

export default LenderInvestmentSlice.reducer;
