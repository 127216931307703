import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useAppDispatch } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router';

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);

  const handleCancel = () => {
    setShowModal(false);
     navigate(-1); 
  };

  const handleConfirm = () => {
    setShowModal(false);
    dispatch(logout());
    navigate('/');
  };

  return (
    <div>
      <Modal isOpen={showModal} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>Logout Confirmation</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to log out?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button color="secondary" onClick={handleCancel}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Logout;
