import axios, { AxiosResponse } from 'axios';
import { Storage } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'app/config/store';
import { serializeAxiosError } from './reducer.utils';
import { AUTHORITIES } from 'app/config/constants';

export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const CUSTOMER_ID = 'customer_id';

export const initialState = {
  loading: false,
  isAuthenticated: Storage.local.get(ACCESS_TOKEN) ? true : false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
  role: Storage.local.get(AUTHORITIES.ROLES),
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession = (): AppThunk => (dispatch, getState) => {
  dispatch(getAccount());
};

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

interface IAuthParams {
  username: string;
  password: string;
  totp?: string;
  user_type?: string;
}

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (auth: IAuthParams) =>
    axios.post<any>(`${SERVER_API_URL}customer-consumer/authentication/login`, auth, {
      headers: { version: 'v1' },
    }),

  {
    serializeError: serializeAxiosError,
  },
);

export const login: (username: string, password: string, totp: string, user_type: string) => AppThunk =
  (username, password, totp, user_type) => async dispatch => {
    const result = await dispatch(authenticate({ username, password, totp, user_type }));
    if (result.type === 'authentication/login/fulfilled') {
      const { access_token, refresh_token, customer_id } = (result.payload as AxiosResponse).data;
      const jwt = access_token;
      const tokenParts = jwt.split('.');
      const decodedPayload = atob(tokenParts[1]);
      const claims = JSON.parse(decodedPayload);
      const roles = claims[AUTHORITIES.ROLES];
      Storage.local.set(ACCESS_TOKEN, access_token);
      Storage.local.set(REFRESH_TOKEN, refresh_token);
      Storage.local.set(CUSTOMER_ID, customer_id);
      Storage.local.set(AUTHORITIES.ROLES, roles);
      dispatch(setRole(roles));
    }
    
  };

export const clearAuthToken = () => {
  if (Storage.local.get(ACCESS_TOKEN)) {
    Storage.local.remove(ACCESS_TOKEN);
  }
  if (Storage.local.get(REFRESH_TOKEN)) {
    Storage.local.remove(REFRESH_TOKEN);
  }
  if (Storage.local.get(CUSTOMER_ID)) {
    Storage.local.remove(CUSTOMER_ID);
  }
  if (sessionStorage.getItem(AUTHORITIES.ROLES)) {
    sessionStorage.removeItem(AUTHORITIES.ROLES);
  }
};

export const logout: () => AppThunk = () => dispatch => {
  clearAuthToken();
  dispatch(logoutSession());
};

export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    logoutSession() {
      return {
        ...initialState,
        isAuthenticated: false,
        showModalLogin: true,
        role: null,
      };
    },
    setRole(state, action) {
      return {
        ...initialState,
        isAuthenticated: true,
        account: {
          authorities: action.payload,
        },
        role: action.payload,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showModalLogin: true,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
        loginSuccess: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        showModalLogin: true,
        loginError: true,
        loginSuccess: false,
        isAuthenticated: false,
      }))
      .addCase(authenticate.fulfilled, (state, action) => {
        const { access_token } = action.payload.data;
        const tokenParts = access_token.split('.');
        const decodedPayload = atob(tokenParts[1]);
        const claims = JSON.parse(decodedPayload);
        return {
          ...state,
          loading: false,
          loginError: false,
          showModalLogin: false,
          loginSuccess: true,
          isAuthenticated: true,
          account: {
            authorities: claims[AUTHORITIES.ROLES],
          },
        };
      })
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(authenticate.pending, state => {
        state.loading = true;
      })
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { logoutSession, authError, clearAuth, setRole } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
