import {
  faCogs,
  faBan,
  faAsterisk,
  faArrowLeft,
  faBell,
  faBook,
  faCloud,
  faDatabase,
  faEye,
  faFlag,
  faHeart,
  faHome,
  faList,
  faLock,
  faPencilAlt,
  faPlus,
  faSave,
  faSearch,
  faSort,
  faSync,
  faRoad,
  faSignInAlt,
  faSignOutAlt,
  faTachometerAlt,
  faTasks,
  faThList,
  faTimesCircle,
  faTrash,
  faUser,
  faUserPlus,
  faUsers,
  faUsersCog,
  faWrench,
  faBrush,
  faAddressCard,
  faEnvelopeOpen,
  faFolder,
  faUserCircle,
  faThLarge,
  faUserAstronaut,
  faHamburger,
  faBars,
  faFileExport,
  faIdCard,
  faFileLines,
  faChalkboard,
  faWallet,
  faBarsProgress,
  faUsersLine,
 } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

export const loadIcons = () => {
  library.add(
    faArrowLeft,
    faAsterisk,
    faBan,
    faBell,
    faBook,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSignInAlt,
    faSignOutAlt,
    faSearch,
    faSort,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimesCircle,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faBrush,
    faAddressCard,
    faEnvelopeOpen,
    faFolder,
    faUserCircle,
    faThLarge,
    faUserAstronaut,
    faHamburger,
    faBars,
    faIdCard,
    faFileExport,
    faFileLines,
    faChalkboard,
    faWallet,
    faBarsProgress,
    faUsersLine,
  );
};
