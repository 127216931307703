export interface IFunderLoanContribution {
  id?: number;
  lenderUniqueId?: string | null;
  fundingAmount?: number | null;
  funderPrincipalSplitPercent?: number | null;
  dailyFinanceFeeAmount?: number | null;
  loanApplicationUniqueId?: string | null;
}

export const defaultValue: Readonly<IFunderLoanContribution> = {};
