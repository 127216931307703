export interface IBorrower {
  id?: number;
  borrowerUniqueId?: string | null;
  borrowerBusinessName?: string | null;
  borrowerBusinessCroNumber?: string | null;
  borrowerBusinessEmail?: string | null;
  borrowerBusinessRepresentPersonName?: string | null;
  borrowerRealBankIban?: number | null;
  borrowerDetails?: string | null;
  data?: string | null;
  totalPages?: number;
}

export const defaultValue: Readonly<IBorrower> = {};
