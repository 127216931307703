import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILoanProfile, defaultValue } from 'app/shared/model/loan-profile.model';
import { cleanEntity } from 'app/shared/util/entity-utils';

const initialState: EntityState<ILoanProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = `${SERVER_API_URL}core-banking/flender/loans/loanProfile`;

// Actions

export const getEntity = createAsyncThunk(
  'loanProfile/fetch_entity',
  async (loanId: string | number) => {
    const requestUrl = `${apiUrl}?loanId=${loanId}`;
    return axios.get<ILoanProfile>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'loanProfile/create_entity',
  async (entity: ILoanProfile, thunkAPI) => {
    return axios.post<ILoanProfile>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'loanProfile/update_entity',
  async (entity: ILoanProfile, thunkAPI) => {
    return axios.put<ILoanProfile>(`${apiUrl}/${entity.loanId}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'loanProfile/partial_update_entity',
  async (entity: ILoanProfile, thunkAPI) => {
    return axios.patch<ILoanProfile>(`${apiUrl}/${entity.loanId}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'loanProfile/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<ILoanProfile>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

// slice

export const LoanProfileSlice = createEntitySlice({
  name: 'loanProfile',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = LoanProfileSlice.actions;

// Reducer
export default LoanProfileSlice.reducer;
